// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/LoggedInPageSections/TestimonialSection.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/LoggedInPageSections/TestimonialSection.tsx");
  import.meta.hot.lastModified = "1728330804384.1716";
}
// REMIX HMR END

import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
const testimonials = [{
  name: 'Michael Douglas',
  text: "Our 7-year-old loves martial arts classes. They've boosted his confidence, he has a blast, and he's made great friends. It's been a fantastic experience!",
  title: 'Chief Executive Officer.',
  image: './assets/testimonial1.jpg'
}];
export function TestimonialSection({}) {
  _s();
  const [index, setIndex] = useState(0);
  return <section aria-labelledby="how-to-get-started" className="pt-24 sm:pt-32 xl:max-w-screen-2xl xl:mx-auto xl:px-8 flow-root">
      <div className="mt-4 w-full flex flex-col items-center justify-start space-y-3 lg:flex-row lg:justify-around lg:items-start lg:space-y-0">
        <div className="flex flex-col justify-center items-center lg:items-end space-y-3">
          <div className="bg-sky-50 rounded-lg w-12 h-12 flex justify-center items-center">
            <ChatBubbleLeftEllipsisIcon color="#4285F4" className="w-10 h-10" />
          </div>
          <h2 className="text-2xl font-semibold color-black dark:text-slate-300 text-center">
            Our clients feedback
          </h2>
          <h3 className="tracking-wide text-normal tracking-tight text-gray-400 text-right">
            Voices from Our Martial Arts Community:
          </h3>
        </div>

        <img src={testimonials[index].image} className="w-[300px] h-[360px] rounded-2xl" />

        <div className="w-full px-4 sm:px-8 lg:px-0 lg:w-[450px] flex flex-col justify-start items-start">
          <p className="italic text-lg lg:text-2xl font-light text-gray-400 dark:text-slate-300 mb-6 lg:leading-loose">
            {testimonials[index].text}
          </p>
          <p className="text-bold text-xl">{testimonials[index].name}</p>
          <p className="text-medium text-gray-400 dark:text-slate-400">
            {testimonials[index].title}
          </p>
        </div>
      </div>
    </section>;
}
_s(TestimonialSection, "x2oTrUAHknTo02Ld7gcDOqaxQ8E=");
_c = TestimonialSection;
var _c;
$RefreshReg$(_c, "TestimonialSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;